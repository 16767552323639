const Theme = {
  buttonBg: "darkorange",
  buttonFg: "white",
  darkBackgroundColor: "#282828",
  lightBackgroundColor: "#383838",
  linkColor: "orange",
  textColor: "white",
  titleColor: "#ffc66d",
  headerBg: "#5fb0e0",
  faintText: "#c0c0c0",

  maxWidth: "90vw",
  shadow: "0 1rem 2rem 0 rgba(0, 0, 0, 0.8)",
}

export default Theme
