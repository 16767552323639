import React, { useContext } from "react"
import PropTypes from "prop-types"

const AnalyticsContext = React.createContext()

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext)
  if (context === undefined) {
    throw new Error("useAnalytics must be used within a AnalyticsProvider")
  }
  return context
}

export const AnalyticsProvider = ({ children }) => {
  let gtag
  if (process.env.NODE_ENV === "production") {
    gtag = (...args) => window.gtag && window.gtag(...args)
  } else {
    gtag = console.log // eslint-disable-line
  }

  // https://developers.google.com/gtagjs/reference/api
  const set = (...args) => gtag("set", ...args)

  const login = (method) => gtag("event", "login", { method })
  const sign_up = (method) => gtag("event", "sign_up", { method })

  const event = ({ category, action, label, value }) => {
    const event_params = { event_category: category }
    if (label !== undefined) {
      event_params.event_label = label
    }
    if (value !== undefined) {
      event_params.value = value
    }
    gtag("event", action, event_params)
  }

  const begin_checkout = (amount, currency, items, coupon) =>
    gtag("event", "begin_checkout", { amount, currency, items, coupon })

  const purchase = (
    transaction_id,
    value,
    currency,
    tax,
    shipping,
    items,
    coupon
  ) =>
    gtag("event", "purchase", {
      transaction_id,
      value,
      currency,
      tax,
      shipping,
      items,
      coupon,
    })

  const add_payment_info = () => gtag("event", "add_payment_info", {})

  return (
    <AnalyticsContext.Provider
      value={{
        gtag,
        set,
        event,
        login,
        sign_up,
        begin_checkout,
        add_payment_info,
        purchase,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
AnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
