import "prism-themes/themes/prism-ghcolors.css"
import "./src/styles/global.css"

import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import Layout from "components/layout"
import Theme from "components/theme"
import { AnalyticsProvider } from "services/analytics"
import { NotificationsProvider } from "services/notifications"
import { OptimizeProvider } from "services/optimize"
import { SentryBoundary, SentryScope } from "services/sentry"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

export const wrapPageElement = ({ element, props }) => {
  return (
    <NotificationsProvider {...props}>
      <SentryScope {...props}>
        <Layout {...props}>{element}</Layout>
      </SentryScope>
    </NotificationsProvider>
  )
}
wrapPageElement.propTypes = {
  element: PropTypes.element.isRequired,
  props: PropTypes.object.isRequired,
}

export const wrapRootElement = ({ element }) => {
  return (
    <SentryBoundary>
      <AnalyticsProvider>
        <OptimizeProvider>
          <ThemeProvider theme={Theme}>
            <Elements stripe={loadStripe(process.env.STRIPE_PK)}>
              {element}
            </Elements>
          </ThemeProvider>
        </OptimizeProvider>
      </AnalyticsProvider>
    </SentryBoundary>
  )
}
wrapRootElement.propTypes = {
  element: PropTypes.element.isRequired,
}
