import React from "react"
import PropTypes from "prop-types"

import Header from "components/header"
import Footer from "components/footer"
import { useNotifications } from "services/notifications"

export default function Layout({ location, children }) {
  const { ModalNotifications } = useNotifications()
  const pathname = location.pathname

  if (pathname.startsWith("/dashboard") || pathname.startsWith("/org")) {
    return (
      <>
        <ModalNotifications />
        {children}
      </>
    )
  } else if (pathname.startsWith("/select-plan")) {
    return (
      <>
        <ModalNotifications />
        {children}
        <Footer />
      </>
    )
  } else {
    return (
      <>
        <Header location={location} />
        <ModalNotifications />
        {children}
        {pathname.startsWith("/login") ||
        pathname.startsWith("/signup") ? null : (
          <Footer />
        )}
      </>
    )
  }
}
Layout.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

// import { OutboundLink } from "gatsby-plugin-google-gtag"
// const ProductHunt = () => (
//   <div className="pt-20">
//     <OutboundLink
//       className="w-full pt-2"
//       href="https://www.producthunt.com/posts/sourcery-4"
//     >
//       <div className="py-4 text-center text-white bg-orange-light hover:bg-yellow-600">
//         <div className="mx-auto">
//           We&apos;re live on Product Hunt 🚀! Please show your love on our
//           product page
//         </div>
//       </div>
//     </OutboundLink>
//   </div>
// )
