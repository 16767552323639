import React, { useContext } from "react"
import PropTypes from "prop-types"

import useGoogleOptimize from "@react-hook/google-optimize"

const OptimizeContext = React.createContext()

export const useOptimize = () => {
  const context = useContext(OptimizeContext)
  if (context === undefined) {
    throw new Error("useOptimize must be used within a OptimizeProvider")
  }
  return context
}

export const OptimizeProvider = ({ children }) => {
  let redesign = useGoogleOptimize("ekpXaNpYQ8qPzeUW8-fIAw", [false, true])
  redesign = false

  return (
    <OptimizeContext.Provider value={{ redesign }}>
      {children}
    </OptimizeContext.Provider>
  )
}
OptimizeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
