import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import github from "images/github.svg"
import linkedin from "images/linkedin.svg"
import twitter from "images/twitter.svg"
import { MailIcon } from "@primer/octicons-react"

const Footer = () => {
  const location =
    typeof window !== "undefined" &&
    window.location.pathname.replaceAll("/", "")

  const pagesWithFooterDark = [
    "privacy",
    "terms",
    "data-processing",
    "changelog",
  ]

  return (
    <>
      <div
        id="Footer"
        className={
          pagesWithFooterDark.includes(location)
            ? "bg-[#111111]"
            : "bg-black-500"
        }
      >
        <div className="max-w-screen-xl mx-auto grid md:grid-cols-5 px-6 py-8 md:justify-center">
          <div className="md:grid md:grid-cols-1 md:mx-auto">
            <div className="mt-6 md:mt-8 font-mono leading-6">
              <h3 className="text-black-300 uppercase text-xs font-bold tracking-[2px]">
                Product
              </h3>
              <ul className="mt-5 space-y-5 text-white font-normal tracking-normal">
                <li className="hover:opacity-60 ">
                  <Link to="/">Code Review</Link>
                </li>
                <li className="hover:opacity-60 ">
                  <Link to="/assistant/">Coding Assistant</Link>
                </li>
                <li className="hover:opacity-60 ">
                  <Link to="/pricing/">Pricing</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-1 md:gap-8 md:mx-auto">
            <div className="mt-6 md:mt-8 font-mono leading-6">
              <h3 className="text-black-300 uppercase text-xs font-bold tracking-[2px]">
                Integrations
              </h3>
              <ul className="mt-5 space-y-5 text-white font-normal tracking-normal">
                <li className="hover:opacity-60 ">
                  <OutboundLink href="https://docs.sourcery.ai/Guides/Getting-Started/VSCode/">
                    VS Code
                  </OutboundLink>
                </li>
                <li className="hover:opacity-60 ">
                  <OutboundLink href="https://docs.sourcery.ai/Guides/Getting-Started/PyCharm/">
                    PyCharm
                  </OutboundLink>
                </li>
                <li className="hover:opacity-60 ">
                  <OutboundLink href="https://docs.sourcery.ai/Guides/Getting-Started/Vim/">
                    Vim
                  </OutboundLink>
                </li>
                <li className="hover:opacity-60 ">
                  <OutboundLink href="https://docs.sourcery.ai/Guides/Getting-Started/Sublime/">
                    Sublime
                  </OutboundLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-1 md:gap-8 md:mx-auto">
            <div className="mt-6 md:mt-8 font-mono  leading-6">
              <h3 className="text-black-300 uppercase text-xs font-bold tracking-[2px]">
                Resources
              </h3>
              <ul className="mt-5 space-y-5 text-white font-normal tracking-normal">
                <li className="hover:opacity-60 ">
                  <Link to="/blog/">Blog</Link>
                </li>
                <li className="hover:opacity-60 ">
                  <Link to="/changelog">Changelog</Link>
                </li>
                <li className="hover:opacity-60 ">
                  <OutboundLink href="https://docs.sourcery.ai">
                    Documentation
                  </OutboundLink>
                </li>
                <li className="hover:opacity-60 ">
                  <OutboundLink
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:hello@sourcery.ai"
                  >
                    Contact us
                  </OutboundLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-1 md:gap-8 md:mx-auto">
            <div className="mt-6 md:mt-8 font-mono leading-6">
              <h3 className="text-black-300 uppercase text-xs font-bold tracking-[2px]">
                Company
              </h3>
              <ul className="mt-5 space-y-5 text-white font-normal tracking-normal">
                <li className="hover:opacity-60 ">
                  <Link to="/about">About us</Link>
                </li>
                <li className="hover:opacity-60 ">
                  <Link to="/privacy/">Privacy policy</Link>
                </li>
                <li className="hover:opacity-60 ">
                  <Link to="/terms/">Terms</Link>
                </li>
                <li className="hover:opacity-60 ">
                  <Link to="/data-processing/">Data Processing Agreement</Link>
                </li>
                <li className="hover:opacity-60 ">
                  <Link to="/data-retention/">Data Retention Policy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-1 md:mx-auto">
            <div className="mt-6 md:mt-8 font-mono leading-6">
              <h3 className="text-black-300 uppercase text-xs font-bold tracking-[2px]">
                COMMUNITY
              </h3>
              <div className="mt-5 font-normal">
                <div className="flex flex-col justify-center space-y-5 md:justify-start">
                  <OutboundLink
                    href="https://twitter.com/sourceryai"
                    className="w-5 text-white hover:opacity-60 flex gap-4 items-center"
                  >
                    <img src={twitter} />
                    <span>Twitter</span>
                  </OutboundLink>
                  <OutboundLink
                    href="https://github.com/sourcery-ai/sourcery"
                    className="w-5 text-white hover:opacity-60 flex gap-4 items-center"
                  >
                    <img src={github} />
                    <span>Github</span>
                  </OutboundLink>
                  <OutboundLink
                    href="https://linkedin.com/company/sourcery-ai"
                    className="w-5 text-white hover:opacity-60 flex gap-4 items-center"
                  >
                    <img src={linkedin} />
                    <span>Linkedin</span>
                  </OutboundLink>
                  <OutboundLink
                    href="mailto:hello@sourcery.ai"
                    className="w-5 text-white hover:opacity-60 flex gap-4 items-center"
                  >
                    <MailIcon verticalAlign="middle" />
                    <span>Email</span>
                  </OutboundLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-orange-200 py-5">
          <div className="w-full flex items-center justify-center text-black-500">
            <p>(ﾉ☉ヮ⚆)ﾉ ⌒*:･ﾟ✧ ˢᴼᵁᴿᶜᴱᴿʸ</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
