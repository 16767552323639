import React, { useCallback, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { XIcon } from "@primer/octicons-react"

const Banner = styled.div`
  margin: 2px 0 0;
  padding: 1.6rem;
  position: relative;
  width: 100%;
  z-index: 50;

  &.modal {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
  }

  &.success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }

  &.info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }

  &.warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
  }

  &.error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }

  div {
    font-size: 1.6rem;
    margin: 0 auto;
    width: 100rem;

    button {
      appearance: none;
      background: none;
      border: 0;
      cursor: pointer;
      float: right;
      margin: -1.6rem;
      opacity: 0.6;
      padding: 1.8rem;
      text-align: center;
    }
  }
`

const NotificationBanner = ({ notification, setNotification, modal }) => {
  if (notification && notification.modal == modal) {
    const className = [notification.type, (modal && "modal") || ""]
      .join(" ")
      .trim()
    return (
      <Banner className={className}>
        <div>
          {notification.message}
          <button onClick={(_) => setNotification()}>
            <XIcon />
          </button>
        </div>
      </Banner>
    )
  } else {
    return null
  }
}
NotificationBanner.propTypes = {
  notification: PropTypes.object,
  setNotification: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
}

const NotificationsContext = React.createContext()

export const useNotifications = () => {
  const context = useContext(NotificationsContext)
  if (context === undefined) {
    throw new Error(
      "useNotifications must be used within a NotificationsProvider"
    )
  }
  return context
}

export const NotificationsProvider = ({ location, children }) => {
  const [notification, setNotification] = useState()

  const showSuccess = useCallback(
    (message, modal) => setNotification({ message, modal, type: "success" }),
    []
  )
  const showInfo = useCallback(
    (message, modal) => setNotification({ message, modal, type: "info" }),
    []
  )
  const showWarning = useCallback(
    (message, modal) => setNotification({ message, modal, type: "warning" }),
    []
  )
  const showError = useCallback(
    (message, modal) => setNotification({ message, modal, type: "error" }),
    []
  )
  const clearNotification = useCallback(() => setNotification(), [])

  useEffect(() => {
    if (location.state) {
      if (location.state.success) {
        showSuccess(location.state.success)
      } else if (location.state.info) {
        showInfo(location.state.info)
      } else if (location.state.warning) {
        showWarning(location.state.warning)
      } else if (location.state.error) {
        showError(location.state.error)
      } else {
        setNotification()
      }
    } else {
      setNotification()
    }
  }, [location, showSuccess, showInfo, showWarning, showError])

  const Notifications = () => (
    <NotificationBanner
      notification={notification}
      setNotification={setNotification}
      modal={false}
    />
  )

  const ModalNotifications = () => (
    <NotificationBanner
      notification={notification}
      setNotification={setNotification}
      modal={true}
    />
  )

  return (
    <NotificationsContext.Provider
      value={{
        Notifications,
        ModalNotifications,
        showSuccess,
        showInfo,
        showWarning,
        showError,
        clearNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}
NotificationsProvider.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}
