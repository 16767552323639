import React, { useEffect } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import * as Sentry from "@sentry/browser"

export class SentryBoundary extends React.Component {
  // TODO Replace with hook when available
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
  }

  render() {
    return this.props.children
  }
}
SentryBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export const SentryScope = ({ location, children }) => {
  useEffect(() => {
    // Login errors from auth0 have an error search param
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.has("error")) {
      Sentry.captureMessage(`Error: ${location.href}`)
      navigate("/", {
        state: {
          error: `Error while logging in: ${searchParams.get("error")}`,
        },
      })
    }
  }, [location])

  return children
}
