module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"release":"website@undefined","environment":"production","integrations":[{"name":"BrowserTracing","_hasSetTracePropagationTargets":false,"options":{"idleTimeout":1000,"finalTimeout":30000,"heartbeatInterval":5000,"markBackgroundTransactions":true,"startTransactionOnLocationChange":true,"startTransactionOnPageLoad":true,"enableLongTask":true,"_experiments":{},"traceFetch":true,"traceXHR":true,"enableHTTPTimings":true,"tracingOrigins":["localhost",{}],"tracePropagationTargets":["localhost",{}]}}],"attachStacktrace":true,"tracePropagationTargets":["https://api.sourcery.ai/.*"],"allowUrls":["https://sourcery.ai"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"85fc57a436ce769e27a55859ae57320b","enableOnDevMode":true,"mixpanelConfig":{"api_host":"https://api-eu.mixpanel.com"},"pageViews":"all"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sourcery Blog","short_name":"Sourcery","start_url":"/blog/","background_color":"#282828","theme_color":"white","display":"browser","icon":"src/images/favicon.png","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"63c3364394c84cae06d42bc320066118"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MK3XTM4","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-W5FS92FMVZ"],"gtagConfig":{"anonymize_ip":true,"optimize_id":"GTM-5FZTLQP","linker":{"domains":["sourcery.ai","docs.sourcery.ai"]}},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"#383838","linkImagesToOriginal":false,"maxWidth":800,"showCaptions":["title"],"markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
